<ng-template #bannerTitle>
  {{ title }}
</ng-template>

@if (mapLoaded) {
  <nz-card
    [nzTitle]="bannerTitle"
    nzBorderless
    class="blue-banner"
    style="width: 100%"
    [nzBodyStyle]="{ display: 'grid', 'grid-gap': '20px', padding: '10px 30px' }"
  >
    <form
      #container
      nz-form
      nzLayout="vertical"
      [formGroup]="locationInfoForm"
      (ngSubmit)="formSubmit(locationInfoForm)"
    >
      <div
        style="display: grid; grid-gap: 0 24px; grid-auto-flow: column; grid-auto-rows: minmax(500px, auto)"
        [style.grid-template-rows]="gridTemplateRows2x2"
        [style.grid-template-columns]="gridTemplateColumns"
      >
        <nz-form-item>
          <nz-form-label>Location Name</nz-form-label>
          <nz-form-control nzHasFeedback>
            <input
              nz-input
              formControlName="name"
              placeholder="enter a name for this alert here"
              type="text"
              (ngModelChange)="ngModelChangeName($event, validating)"
            />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Types of Alerts</nz-form-label>
          <div style="display: grid; grid-template-columns: repeat(2, 50%)">
            @for (category of categories.controls; track category; let index = $index) {
              <nz-form-control formArrayName="categories">
                <label nz-checkbox style="display: flex; margin: 15px 0" [formControlName]="index">
                  {{ categoryList[index] | titlecase }}
                </label>
              </nz-form-control>
            }
          </div>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>ZIP Code</nz-form-label>
          <nz-form-control nzHasFeedback nzErrorTip="Please input a valid 5 digit zip code.">
            <input
              nz-input
              formControlName="postal"
              autocomplete="postal"
              placeholder="enter a ZIP code here"
              type="text"
              (ngModelChange)="ngModelChangePostal($event, validating)"
            />
          </nz-form-control>
        </nz-form-item>

        <div style="max-width: 400px">
          <google-map
            id="googlemap"
            width="100%"
            height="350px"
            style="max-width: 400px"
            [center]="center"
            [options]="mapOptions"
          >
            <map-rectangle [bounds]="postalBounds" [options]="postalOptions"></map-rectangle>
          </google-map>
        </div>
      </div>

      <div
        style="display: grid; grid-gap: 0 24px; grid-auto-flow: column; grid-auto-rows: minmax(500px, auto)"
        [ngStyle]="{ 'grid-template-columns': gridTemplateColumns, 'grid-template-rows': gridTemplateRows1x2 }"
      >
        <div>
          <nz-form-label>Phone Number</nz-form-label>

          @for (control of phones.controls; track control; let index = $index; let first = $first) {
            <nz-form-item
              style="display: grid; gap: 10px; grid-template-columns: auto min-content; align-items: flex-start"
            >
              <nz-form-control
                formArrayName="phones"
                nzErrorTip="Please input phone number or delete this field."
                nzHasFeedback
              >
                <input
                  nz-input
                  autocomplete="tel"
                  placeholder="enter your phone number here"
                  [formControlName]="index"
                  (ngModelChange)="ngModelChangePhone($event, validating, index)"
                />
              </nz-form-control>
              <button nz-button nzType="primary" (click)="removePhone(index)">delete</button>
            </nz-form-item>
          }

          @if (phones.controls.length < 5) {
            <nz-form-item>
              <nz-form-control>
                <button nz-button nzType="dashed" (click)="addPhone(''); $event.preventDefault()">
                  <i nz-icon nzType="plus-circle-o"></i>
                  Add phone
                </button>
              </nz-form-control>
            </nz-form-item>
          }
        </div>
        <div>
          <nz-form-label>Email Address</nz-form-label>

          @for (control of emails.controls; track control; let index = $index; let first = $first) {
            <nz-form-item
              style="display: grid; gap: 10px; grid-template-columns: auto min-content; align-items: flex-start"
            >
              <nz-form-control
                [formArrayName]="'emails'"
                nzErrorTip="Please input email address or delete this field."
                nzHasFeedback
              >
                <input
                  nz-input
                  required
                  autocomplete="mail"
                  placeholder="enter your email address here"
                  [formControlName]="index"
                  (ngModelChange)="ngModelChangeEmail($event, validating, index)"
                />
              </nz-form-control>
              <button nz-button nzType="primary" (click)="removeEmail(index)">delete</button>
            </nz-form-item>
          }

          @if (emails.controls.length < 1) {
            <nz-form-item>
              <nz-form-control>
                <button nz-button nzType="dashed" (click)="addEmail(''); $event.preventDefault()">
                  <i nz-icon nzType="plus"></i>
                  Add Email Address
                </button>
              </nz-form-control>
            </nz-form-item>
          }
        </div>
      </div>

      @if (swsSmsStateValue) {
        <div style="display: flex; justify-content: center; margin: 30px 0">
          @if (swsSmsStateValue === 'location' || swsSmsStateValue === 'cloudSync') {
            <div>
              @if (swsSmsStateValue !== 'cloudSync') {
                <button
                  nz-button
                  nzType="primary"
                  type="submit"
                  style="width: 120px; margin: 10px"
                  (click)="eventType = 'SAVE'"
                  [disabled]="this.locationInfoForm.invalid && validating"
                >
                  Save
                </button>
              }

              @if (swsSmsStateValue === 'cloudSync') {
                <button nz-button nzType="primary" style="width: 120px; margin: 10px">Saving</button>
              }

              @if (swsSmsStateValue !== 'cloudSync') {
                <button nz-button nzType="primary" style="width: 120px; margin: 10px" (click)="eventType = 'CANCEL'">
                  Cancel
                </button>
              }
            </div>
          }

          @if (swsSmsStateValue === 'cloudSyncError') {
            <div>
              <button nz-button nzType="primary" style="width: 120px; margin: 10px" (click)="eventType = 'RETRY'">
                Retry
              </button>
              <button nz-button nzType="primary" style="width: 120px; margin: 10px" (click)="eventType = 'RELOAD'">
                Cancel
              </button>
            </div>
          }
        </div>
      }
    </form>
  </nz-card>
}
