import { AfterViewInit, Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';
import { WINDOW } from '@ng-web-apis/common';
import { tap } from 'rxjs/operators';
import { GLOBAL_RX_STATE, GlobalState } from '../../../../../services/state';
import { Flow, HOOSIER_RX_STATE, HoosierService, HoosierState, MembershipCardData } from '../../hoosier.service';
import { Membership } from '../../services/member-info';
import { combineLatestWith } from 'rxjs';

export interface ConfirmedMember extends MembershipCardData {
  membershipUserId?: string;
}

@Component({
  selector: 'ava-hoosier-confirmation-details',
  templateUrl: './confirmation-details.html',
  providers: [RxState, RxEffects],
})
export class ConfirmationDetailsComponent implements AfterViewInit {
  @ViewChild('componentWidth') componentWidthElementRef: ElementRef | undefined;
  hoosierState$ = this.hoosierState.select();

  members: ConfirmedMember[] = [];
  membership: Membership | undefined;
  flexDirection: 'row' | 'column' = 'row';
  Flow = Flow;

  constructor(
    @Inject(WINDOW)
    private window: Window,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    @Inject(HOOSIER_RX_STATE)
    private hoosierState: RxState<HoosierState>,
    private hoosierService: HoosierService,
    private rxEffects: RxEffects,
  ) {
    rxEffects.register(this.PAYMENT$);
    rxEffects.register(this.PAYMENT$_MEMBERSHIP_ERROR$);
    rxEffects.register(this.mockMembers$);
    rxEffects.register(this.flexDirection$);
  }

  flexDirection$ = this.globalState.select('pageWidth').pipe(
    tap((pageWidth) => {
      /*
                let flexDirection = "row"
                if (pageWidth <= BreakpointWidth.MEDIUM) {
                  flexDirection = "column"
                }
                this.flexDirection = flexDirection
        */
    }),
  );

  mockMembers$ = this.globalState.select('adminMode').pipe(
    tap((adminMode) => {
      let members: ConfirmedMember[] = [];
      if (adminMode) {
        members = this.getMembersFromMembershipResponse();
      }
      if (!adminMode && this.membership) {
        members = this.getMembersFromMembershipResponse();
      }
      this.members = members;
    }),
  );

  PAYMENT$ = this.hoosierState.select('PAYMENT').pipe(
    tap((PAYMENT) => {
      if (PAYMENT) {
        this.window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    }),
  );

  PAYMENT$_MEMBERSHIP_ERROR$ = this.hoosierState.select('PAYMENT').pipe(
    combineLatestWith(this.hoosierState.select('MEMBERSHIP_ERROR')),
    tap(([PAYMENT, MEMBERSHIP_ERROR]) => {
      if (PAYMENT && MEMBERSHIP_ERROR) {
        this.members = this.getMembersFromMembershipPayload();
      }
    }),
  );

  ngAfterViewInit(): void {
    this.onResize();
    // this.registerPreviewSideEffects()
  }

  @HostListener('window:resize')
  onResize(): void {
    const width = this.componentWidthElementRef?.nativeElement.getBoundingClientRect().width;
    if (width <= 600) {
      this.flexDirection = 'column';
    } else {
      this.flexDirection = 'row';
    }
  }

  getMembersFromMembershipPayload(): ConfirmedMember[] {
    return [];
  }

  getMembersFromMembershipResponse(): ConfirmedMember[] {
    return [];
  }
}
