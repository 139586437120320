import { Component, Input, OnInit } from '@angular/core';
import { StateMachineService } from '../../../services/state-machine.service';
import { FormService } from '../../../services/form.service';
import { AppMode, FormValues, Meeting } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-meeting',
  template: `
    <div style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400">What type of appointment?</div>
    <div style="display: grid; justify-content: center">
      <nz-form-label nzFor="meeting" style="visibility: hidden">Select Meeting</nz-form-label>
      <nz-select nzId="meeting" [(ngModel)]="formValue!.selectedMeetingId" style="min-width: 250px">
        @for (meeting of meetings; track meeting) {
          <nz-option [nzValue]="meeting.id" [nzLabel]="meeting.text"></nz-option>
        }
      </nz-select>
    </div>
    <div style="display: flex; justify-content: center; margin: 10px">
      <button nz-button nzType="primary" (click)="sms.sendEvent('NEXT'); $event.preventDefault()">
        {{ formValue!.overrides.agentEmail ? 'Next' : buttonLabel }}
      </button>
    </div>
  `,
})
export class MeetingComponent implements OnInit {
  @Input() formId: string | undefined;
  formValue: FormValues | undefined;
  buttonLabel = 'Next';
  meetings: Meeting[] = [];
  formAgentValue: any | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValue = this.formService.formValues[this.formId];
      this.formAgentValue = this.formService.form[this.formId]?.get(['agent'])?.value;
    }
    if (this.formValue) {
      this.formValue.selectedMeetingId = '';
      if (this.formValue.appMode === AppMode.TRAVEL) {
        this.buttonLabel = 'FIND A TRAVEL AGENT';
      }
      if (this.formValue.appMode === AppMode.INSURANCE) {
        this.buttonLabel = 'FIND INSURANCE AGENT';
      }

      if (this.formAgentValue) {
        /**
         * filter meetings options
         * show only meetings options that the selected agent supports.
         */
        this.meetings = this.formValue.meetings.filter((meeting) =>
          this.formAgentValue.meetingIds.some((meetingId: string) => meetingId === meeting.id),
        );

        /**
         * If selectedMeeting is not populated then select the first meeting.id with meeting.default:true
         * this is kinda hacky....but
         *    look for default meeting in the filtered list of meetings,
         *    if not found, then use the first one from the filtered list.
         */
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.meetings.find((meeting) => !!meeting.default)?.id || '';
        }
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.meetings[0].id;
        }
      }
      if (!this.formAgentValue) {
        /**
         * load all meetings options
         */
        this.meetings = this.formValue.meetings;
        /**
         * If selectedMeeting is not populated then select the first meeting.id with meeting.default:true
         */
        if (!this.formValue.selectedMeetingId) {
          this.formValue.selectedMeetingId = this.formValue.meetings.find((meeting) => !!meeting.default)?.id || '';
        }
      }
    }
  }
}
