<div #container>
  @if (!agentCount) {
    No Available Agents
  }

  @if (agentCount && sms.state) {
    @if (!sms.state.matches('confirmation')) {
      <ava-progress-bar [formId]="formId" />
    }

    @if (sms.state.matches('topic')) {
      <ava-topic [formId]="formId" />
    }

    @if (sms.state.matches('region')) {
      <ava-region [formId]="formId" />
    }

    @if (sms.state.matches('insuranceOptions')) {
      <ava-insurance-options [formId]="formId" />
    }

    @if (sms.state.matches('meeting')) {
      <ava-meeting [formId]="formId" />
    }

    @if (sms.state.matches('agentsList')) {
      <ava-agents-list [formId]="formId" [containerWidth]="containerWidth" />
    }

    @if (sms.state.matches('agentPage')) {
      <ava-agent [formId]="formId" />
    }

    @if (sms.state.matches('userInfo')) {
      <ava-user-info [formId]="formId" />
    }

    @if (sms.state.matches('confirmation')) {
      <ava-confirmation [formId]="formId" />
    }
    <br />
  }

  <ava-tools [formId]="formId" />
</div>
