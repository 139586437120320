import { Component, Input, OnInit } from '@angular/core';
import { StateMachineService } from '../../../services/state-machine.service';
import { FormService } from '../../../services/form.service';
import { FormArray } from '@angular/forms';
import { FormValues } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-topic',
  template: `
    <style>
      .selected {
        box-shadow:
          0 3px 1px -2px #00529b,
          0 2px 2px 0 #00529b,
          0 1px 5px 0 #00529b;
      }
    </style>
    <div style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400">
      What would you like to talk about?
    </div>

    <div style="display: flex; flex-wrap: wrap; justify-content: center">
      @for (topic of topics!.controls; track topic; let index = $index) {
        <nz-card
          nzHoverable
          tabindex="0"
          role="button"
          [ngClass]="{ selected: topic.value }"
          style="
        width: 140px;
        height: 120px;
        color: #036ecd;
        margin: 10px;
        display: grid;
        align-content: center;
        justify-items: center;
      "
          [nzBodyStyle]="{ display: 'grid', 'justify-items': 'center', padding: '0', 'max-width': '120px' }"
          [attr.aria-selected]="topic.value"
          (keydown.space)="toggleTopic(index)"
          (keydown.enter)="toggleTopic(index)"
          (click)="toggleTopic(index)"
        >
          <div class="icon" [class]="formValues.topics[index].icon" style="color: #666666; font-size: 26px"></div>
          <div style="text-align: center; font-size: 20px; font-weight: 400">
            {{ formValues.topics[index].text }}
          </div>
        </nz-card>
      }
    </div>

    <div style="display: flex; justify-content: center; margin: 10px">
      <button
        nz-button
        nzType="primary"
        (click)="sms.sendEvent('NEXT'); $event.preventDefault()"
        [disabled]="noTopicSelected"
      >
        Next
      </button>
    </div>
  `,
})
export class TopicComponent implements OnInit {
  @Input() formId: string | undefined;
  formValues: FormValues | undefined;
  noTopicSelected = true;
  topics: FormArray | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId];
      this.topics = this.formService.form[this.formId].get('topics') as FormArray;
    }
    this.noTopicSelected = !this.topics?.controls.find((topic) => topic.value);
  }

  toggleTopic(index: number): void {
    this.topics?.controls[index].setValue(!this.topics?.controls[index].value);
    /**
     * The form values for topics are simple true or false.
     * We map from formService.topics to formService.selectedTopics with each topic selection change.
     */
    if (this.formValues) {
      this.formValues.selectedTopicIds = [];
      this.topics?.controls.forEach((topicControl, topicControlIndex) => {
        if (topicControl.value) {
          this.formValues?.selectedTopicIds.push(this.formValues.topics[topicControlIndex].id);
        }
      });
      this.noTopicSelected = !this.topics?.controls.find((topic) => topic.value);
    }
  }
}
