import { Component, Input, OnInit } from '@angular/core';
import { StateMachineService } from '../../../services/state-machine.service';
import { FormService } from '../../../services/form.service';
import { FormValues, Region } from '@aaa/interface-agentScheduler';

@Component({
  selector: 'ava-region',
  template: `
    <div style="text-align: center; margin: 10px; font-size: 30px; font-weight: 400">Where would you like to travel?</div>
    <div style="display: grid; justify-content: center">
      <nz-form-label nzFor="region" style="visibility: hidden">Select Region</nz-form-label>
      <nz-select nzId="region" [(ngModel)]="formValue!.selectedRegionId" style="min-width: 250px">
        @for (region of regions; track region) {
          <nz-option [nzValue]="region.id" [nzLabel]="region.text"></nz-option>
        }
      </nz-select>
    </div>
    <div style="display: flex; justify-content: center; margin: 10px">
      <button nz-button nzType="primary" (click)="sms.sendEvent('NEXT'); $event.preventDefault()">Next</button>
    </div>

  `
})
export class RegionComponent implements OnInit {
  @Input() formId: string | undefined;
  formValue: FormValues | undefined;
  regions: Region[] = [];
  formAgentValue: any | undefined;

  constructor(
    public sms: StateMachineService,
    public formService: FormService,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValue = this.formService.formValues[this.formId];
      this.formValue.selectedRegionId = '';
      this.formAgentValue = this.formService.form[this.formId].get(['agent'])?.value;
    }

    if (this.formValue) {
      if (this.formAgentValue) {
        /**
         * filter regions options
         * show only regions options that the selected agent supports.
         */
        this.regions =
          this.formValue?.regions.filter((region) =>
            this.formAgentValue.regionIds.some((regionId: string) => regionId === region.id),
          ) || [];

        /**
         * If selectedRegionId is not populated then select the first region.id with region.default:true
         * this is kinda hacky....but
         *    look for default region in the filtered list of regions,
         *    if not found, then use the first one from the filtered list.
         */
        if (!this.formValue.selectedRegionId) {
          this.formValue.selectedRegionId = this.regions.find((region) => !!region.default)?.id || '';
        }
        if (!this.formValue?.selectedRegionId) {
          this.formValue.selectedRegionId = this.regions[0].id;
        }
      }
      if (!this.formAgentValue) {
        /**
         * load all regions options
         */
        this.regions = this.formValue.regions;
        /**
         * If selectedRegionId is not populated then select the first region.id with region.default:true
         */
        if (!this.formValue.selectedRegionId) {
          this.formValue.selectedRegionId = this.formValue.regions.find((region) => !!region.default)?.id || '';
        }
      }
    }
  }
}
