import { JwtAuthService } from '../../services/jwt-auth';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { GcpConnectorService } from './services/gcp-connector.service';
import { SwStateMachineService } from './services/sw-state-machine.service';
import { combineLatestWith, Subscription, tap } from 'rxjs';
import { StateSyncService } from './services/state-sync.service';
import { AnalyticsService } from '../../services/analytics';
import { DOCUMENT } from '@angular/common';
import { GLOBAL_RX_STATE, GlobalState } from '../../services/state';
import { RxState } from '@rx-angular/state';
import { RxEffects } from '@rx-angular/state/effects';

@Component({
  selector: 'ava-smart-weather',
  template: `
    @if (globalState$ | async; as g) {
      <div #container [style]="'padding: 20px 0'">
        @if (!g.windowMetaData.user.memberNumber) {
          <div
            [style]="
              'display: grid; column-gap: 20px; padding: 20px; grid-template-columns: 1fr 1fr; max-width: 50%; margin: auto'
            "
            [ngStyle]="buttonWrapperStyles"
          >
            <button nz-button nzType="primary">
              <a [href]="'/smart-weather-login'">Sign Up</a>
            </button>
            <button nz-button nzType="primary">
              <a [href]="'/smart-weather-login'">Manage Account</a>
            </button>
          </div>
        }

        @if (g.windowMetaData.user.memberNumber && g.afAuthIdTokenResult) {
          <nz-card
            [nzTitle]="bannerTitle"
            nzBorderless
            class="blue-banner"
            [style]="'width: 100%'"
            [nzBodyStyle]="{ display: 'grid', 'grid-gap': '20px', padding: '10px 30px' }"
          >
            <ng-template #bannerTitle>Manage Your Weather Alerts</ng-template>
            <div [style]="'text-align: left'">You can manage up to 5 locations</div>

            @if (state) {
              @if (state.list || state.location) {
                <ava-smart-weather-list />
              }

              @if (state.cloudSync) {
                <ava-smart-weather-loading />
              }

              @if (state.cloudSyncError) {
                <ava-smart-weather-error />
              }
            }
          </nz-card>
        }
      </div>
    }
  `,
})
export class SmartWeatherComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('container') container: ElementRef | undefined;
  globalState$ = this.globalState.select();
  containerWidth: number | undefined;
  buttonWrapperStyles: { [key: string]: string } = {};
  stateSubscription: Subscription | undefined;
  userSubscription: Subscription | undefined;
  state:
    | {
        cloudSync: boolean;
        cloudSyncError: boolean;
        list: boolean;
        location: boolean;
      }
    | undefined;
  locations: any[] = [];

  constructor(
    @Inject(DOCUMENT)
    private document: Document,
    @Inject(GLOBAL_RX_STATE)
    private globalState: RxState<GlobalState>,
    private rxEffects: RxEffects,
    private auth: JwtAuthService,
    private gcpConnector: GcpConnectorService,
    private swSms: SwStateMachineService,
    private stateSyncService: StateSyncService,
    private analyticsService: AnalyticsService,
  ) {
    rxEffects.register(this.setup$);
    rxEffects.register(this.swSmsState$);
  }

  setup$ = this.globalState.select('afAuthIdTokenResult').pipe(
    combineLatestWith(
      this.globalState.select('windowMetaData', 'user'),
      this.globalState.select('windowMetaData', 'clubId'),
    ),
    tap(async ([afAuthIdTokenResult, user, clubId]) => {
      if (afAuthIdTokenResult && user?.firstName && user?.lastName && user?.memberNumber && clubId) {
        await this.gcpConnector.setupService(clubId);
        await this.stateSyncService.setupService(clubId, user.memberNumber);
        this.swSms.setupService(user.memberNumber, user.firstName, user.lastName);
      }
    }),
  );

  swSmsState$ = this.swSms.state$.pipe(
    tap((swSmsState) => {
      if (swSmsState) {
        this.state = {
          cloudSync: swSmsState.value === 'cloudSync',
          cloudSyncError: swSmsState.value === 'cloudSyncError',
          list: swSmsState.value === 'list',
          location: swSmsState.value === 'location',
        };
        if (swSmsState.context.locations) {
          this.locations = swSmsState.context.locations;
        }
      }
    }),
  );

  ngOnInit(): void {
    const userId = this.globalState.get('windowMetaData', 'user', 'id');
    if (userId && userId !== '1') {
      this.analyticsService.sendPage(window.location.pathname.toLowerCase() + '/smart-weather-form');
    }
  }

  ngOnDestroy(): void {
    if (this.userSubscription) {
      this.userSubscription?.unsubscribe();
    }
    if (this.stateSubscription) {
      if (this.stateSubscription) {
        this.stateSubscription.unsubscribe();
      }
    }
    if (this.stateSyncService) {
      this.stateSyncService?.unsubscribe();
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth;

    this.buttonWrapperStyles = {};
    if (this.containerWidth && this.containerWidth >= 760) {
      this.buttonWrapperStyles = { 'max-width': '50%' };
    }
  }
}
