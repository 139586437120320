<div style="text-align: center">
  @if (bs.clubDetails) {
    <div [innerHTML]="bs.clubDetails.noResultsHtml"></div>
  }

  Call
  <a href="tel:1-800-222-4357">1-800-222-4357</a>
  <a (click)="bs.setComponentState('buttonClicked', this.bs.ComponentState.FORM, $event)">
    or start a new battery search.
  </a>
</div>
