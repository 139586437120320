import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ViewChild,
  ElementRef,
  HostListener,
  AfterViewInit,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { AgentTimeSlot, AgentAvailability, FormValues, Office, AgentType } from '@aaa/interface-agentScheduler';
import { AzureConnectorService } from '../../../services/azure-connector.service';
import { FirestoreConnectorService } from '../../../services/firestore-connector.service';
import { StateMachineService } from '../../../services/state-machine.service';
import { differenceInCalendarDays } from 'date-fns';
import { FormService } from '../../../services/form.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'ava-agent',
  templateUrl: './agent.component.html',
})
export class AgentComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() formId: string | undefined;
  formValues: FormValues | undefined;
  @ViewChild('container') container: ElementRef | undefined;
  containerWidth: number | undefined;
  gridTemplateColumns: { [key: string]: string } = {};
  appointmentCardStyles: { [key: string]: string } = {};
  dayTimeSlots: AgentTimeSlot[] = [];
  availabilitySubscription: Subscription | undefined;
  timeSlots: AgentTimeSlot[] = [];
  agentOffice: Office | undefined;
  agentTypes: (AgentType | undefined)[] = [];

  minDate: Date | undefined;
  maxDate: Date | undefined | null;
  agent: FormControl | undefined;
  agentTimezone: FormControl | undefined;
  date: FormControl | undefined;
  timeSlot: FormControl | undefined;

  constructor(
    public sms: StateMachineService,
    public fcs: FirestoreConnectorService,
    public acs: AzureConnectorService,
    public formService: FormService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.formId) {
      this.formValues = this.formService.formValues[this.formId];
      this.agent = this.formService.form[this.formId].get('agent') as FormControl;
      this.agentTimezone = this.formService.form[this.formId].get('agentTimezone') as FormControl;
      this.date = this.formService.form[this.formId].get('date') as FormControl;
      this.timeSlot = this.formService.form[this.formId].get('timeSlot') as FormControl;
    }
    if (this.formValues) {
      this.agentOffice = this.formValues.offices.find((office) => office.id === this.agent?.value.officeIds[0]);

      const agentTypes: { [key: string]: AgentType | undefined } = {};
      this.agent?.value.typeIds.forEach((typeId: string) => {
        agentTypes[typeId] = this.formValues?.types.find((type) => type.id === typeId);
      });
      this.agentTypes = Object.values(agentTypes);

      // this.agentTypes = this.formValues.types.find(type => !!this.agent.value.typeIds.find(typeId => typeId === type.id))
      this.date?.reset();
      this.timeSlot?.reset();
      this.minDate = new Date();
      this.minDate.setHours(this.minDate.getHours() + 2);
      this.agentTimezone?.setValue('');

      this.availabilitySubscription = this.fcs.availability$.subscribe((agents) => {
        const agentAvailability: AgentAvailability = agents.filter(
          (agent) => agent.agentEmail && agent.agentEmail.toLowerCase() === this.agent?.value.email.toLowerCase(),
        )[0];
        this.timeSlots = agentAvailability?.timeSlots;
        this.agentTimezone?.setValue(agentAvailability?.timezone);
      });
    }
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngOnDestroy(): void {
    this.availabilitySubscription?.unsubscribe();
  }

  @HostListener('window:resize')
  onResize(): void {
    this.containerWidth = this.container?.nativeElement.offsetWidth;

    this.gridTemplateColumns = { 'grid-template-columns': 'auto' };
    this.appointmentCardStyles = {};
    if (this.containerWidth && this.containerWidth >= 700) {
      this.gridTemplateColumns = { 'grid-template-columns': '210px auto' };
      this.appointmentCardStyles = { 'grid-column': '1 / 3' };
    }
    if (this.containerWidth && this.containerWidth >= 1080) {
      this.gridTemplateColumns = { 'grid-template-columns': '210px auto 300px' };
      this.appointmentCardStyles = {};
    }
    this.changeDetectorRef.detectChanges();
  }

  dateChanged(datePickerDate: Date): void {
    this.timeSlot?.reset();
    if (datePickerDate) {
      const today = new Date();
      const selectedDateTime = new Date(
        datePickerDate.getFullYear(),
        datePickerDate.getMonth(),
        datePickerDate.getDate(),
        today.getHours(),
        today.getMinutes(),
        today.getSeconds(),
        0,
      );
      const timeSlots = this.getAvailabilityForDate(this.timeSlots, selectedDateTime);
      this.dayTimeSlots = timeSlots.sort((a, b) => a.time.hour - b.time.hour);
    } else {
      this.dayTimeSlots = [];
    }
  }

  bookWith(): void {
    if (this.timeSlot?.value) {
      this.sms.sendEvent('NEXT');
    }
  }

  private getAvailabilityForDate(timeSlots: AgentTimeSlot[], pickerDate: Date): AgentTimeSlot[] {
    const now = new Date();
    now.setHours(now.getHours() + 2);
    return timeSlots.filter((timeSlot) => {
      const agentTimeSlotToDate = this.acs.agentTimeSlotToDate(timeSlot);
      return (
        timeSlot.date.year === pickerDate.getFullYear() &&
        timeSlot.date.month - 1 === pickerDate.getMonth() &&
        timeSlot.date.day === pickerDate.getDate() &&
        agentTimeSlotToDate &&
        agentTimeSlotToDate > now
      );
    });
  }

  datePickerDisabledDates = (pickerDate: Date): boolean => {
    return !this.timeSlots?.some((timeSlot) => {
      return (
        timeSlot.date.year === pickerDate.getFullYear() &&
        timeSlot.date.month - 1 === pickerDate.getMonth() &&
        timeSlot.date.day === pickerDate.getDate() &&
        differenceInCalendarDays(pickerDate, new Date()) >= 0
      );
    });
  };
}
