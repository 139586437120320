@if (showTools) {
  <div>
    <button nz-button nzType="primary" (click)="showAccordion = !showAccordion">Tools</button>

    @if (showAccordion) {
      <div style="margin: 10px">
        <nz-collapse>
          @if (tools.info.enabled) {
            <nz-collapse-panel nzHeader="Info">
              Project ID:
              {{ tools.info.projectId }}
              <br />
              Endpoint URL:
              {{ tools.info.endpointUrl }}
            </nz-collapse-panel>
          }

          @if (tools.reports.enabled) {
            <nz-collapse-panel nzHeader="Reports">
              <div [style]="'margin-bottom: 10px'">
                <nz-range-picker
                  [(ngModel)]="date"
                  (ngModelChange)="datePickerRange($event)"
                  [nzDisabledDate]="datePickerDisabledDates"
                />
              </div>
              <button
                nz-button
                [nzType]="'primary'"
                (click)="reportsDownload()"
                [disabled]="!tools.reports.range.start || !tools.reports.range.end"
              >
                Download Report
              </button>
            </nz-collapse-panel>
          }

          @if (tools.connection.enabled) {
            <nz-collapse-panel nzHeader="Azure Connection">
              <button
                nz-button
                [nzType]="'primary'"
                (click)="connectionAgentsRefresh()"
                [disabled]="tools.connection.loading"
              >
                Refresh Agents
              </button>
              <pre>{{ tools.connection.appId | json }}</pre>
              <pre>{{ tools.connection.results | json }}</pre>
            </nz-collapse-panel>
          }

          @if (tools.hours.enabled) {
            <nz-collapse-panel nzHeader="Agent Hours">
              <button nz-button nzType="primary" (click)="hoursGetAgentHours()" [disabled]="tools.hours.loading">
                Get Agent Work Hours
              </button>
              <pre>{{ tools.hours.results | json }}</pre>
            </nz-collapse-panel>
          }

          @if (tools.fnConfig.enabled) {
            <nz-collapse-panel nzHeader="Function Configuration">
              <pre> {{ tools.fnConfig.doc }} </pre>
            </nz-collapse-panel>
          }
        </nz-collapse>
      </div>
    }
  </div>
}
